<template>
  <div class="container d-flex align-items-center justify-content-center mt-4">
    <div class="row w-100 align-items-center justify-content-center py-5 mt-5">
      <div class="title text-center">
        <h2>Reset Password</h2>
      </div>
      <div class="col-lg-6 reset_password py-5 mt-5">
        <form class="p-4" @submit.prevent="onSubmit">
          <div class="form-group mt-3">
            <label for="new" class="form-label">New password</label>
            <div class="eye_container">
              <input
                id="new"
                :type="showType.newPassword"
                v-model="form.newPassword"
                class="form-control"
              />
              <Eye
                @click="toggleShowPassword('newPassword')"
                :hide-show="type.newPassword"
              />
            </div>
          </div>
          <div class="form-group mt-3">
            <label for="repeat" class="form-label">Repeat password</label>
            <div class="eye_container">
              <input
                id="repeat"
                :type="showType.repeatPassword"
                v-model="form.repeatPassword"
                class="form-control"
              />
              <Eye
                @click="toggleShowPassword('repeatPassword')"
                :hide-show="type.repeatPassword"
              />
            </div>
          </div>
          <div class="row mt-2">
            <div class="col d-flex align-items-center justify-content-center">
              <button class="btn btn-secondary mt-3" type="submit">
                Reset
              </button>
            </div>
          </div>
          <div class="row mt-3">
            <div class="col text-center forgot_password">
              <p>Forgot Password?</p>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import Eye from '../Components/Eye.vue'
export default {
  name: 'ResetPassword',
  data() {
    return {
      type: {
        newPassword: 'password',
        repeatPassword: 'password',
      },
      form: {
        newPassword: '',
        repeatPassword: '',
      },
      error: [],
    }
  },
  components: {
    Eye,
  },
  computed: {
    showType() {
      return this.type
    },
  },
  methods: {
    onSubmit() {
      console.log(this.form)
    },
    toggleShowPassword(key) {
      this.type[key] = this.type[key] === 'password' ? 'text' : 'password'
    },
  },
}
</script>

<style lang="scss" src="./style.scss" />
